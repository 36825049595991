@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');


* {
	font-family: 'Poppins', sans-serif;

  &:focus {
    outline: none;
  }
}

html {
  scroll-behavior: smooth;
}

.form-group {
  position: relative;
  width: 100%;
}

.row {
  margin-right: 0;
  margin-left: 0;
  height: 40px;
}

[class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.pointer {
  cursor: pointer;
}

.invalid-feedback {
  display: block;
  font-size: 0.76rem;
  margin-bottom: -12px;
  margin-top: 2px;
  font-weight: initial;
}

.form-inline {
  .form-group {
    @include respond-below(xs) {
      width: 100%;
    }
  }
}

.form-control {
  box-shadow: none !important;
}

.form-check-label,
.custom-control-label {
  line-height: 24px !important;
}

.custom-control-input:disabled~.custom-control-label::before {
  background-color: rgba($success-color, 0.25) !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:checked~.custom-control-label::before {
  border: 1px solid $success-color;
  background-color: $success-color;
  box-shadow: initial !important;
}

.custom-control-label::after,
.custom-control-label::before {
  box-shadow: initial !important;
  outline: initial !important;
}

.pagination {
  li {
    cursor: pointer;
    color: #333;
    font-size: 16px;
    line-height: 19px;
    border-radius: 10%;
    width: 31px;
    height: 31px;
    align-items: center;
    display: flex;
    justify-content: center;

    &:first-child,
    &:last-child {
      width: 40px;
      height: 40px;
    }

    &:first-child {
      padding-right: 2px;
    }

    &:last-child {
      padding-left: 2px;
    }

    .fi {
      color: $primary-color;
      font-size: 21px;
      padding: 5px;
    }

    &.active,
    &:hover {
      background-color: $primary-color;
      color: #fff;

      .fi {
        color: #fff;
      }
    }

    &.disabled {
      border-color: #d7d7d7;
      color: #d7d7d7;
      background: transparent;
      pointer-events: none;
      cursor: auto;

      .fi {
        color: #d7d7d7;
      }
    }
  }

  li+li {
    margin-left: 8px;
  }
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

button {
  color: $primary-text-color;
  outline: initial !important;
}

.btn {
  border-radius: $border-radius;
}

.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
  color: #fff;

  &:hover {
    color: #fff;
    background-color: darken($primary-color, 8%);
    border-color: darken($primary-color, 8%);
  }
}

.btn-outline-primary {
  color: $primary-color;
  border-color: $primary-color;

  &:hover {
    color: #fff;
    background-color: $secondary-color;
    border-color: $primary-color;
  }
}

.btn-success {
  background-color: $primary-color;
  border-color: $success-color;
  color: #fff;

  &:hover {
    color: #fff;
    background-color: darken($success-color, 8%);
    border-color: darken($success-color, 8%);
  }
}

.btn-outline-success {
  color: $success-color;
  border-color: $success-color;

  &:hover {
    color: #fff;
    background-color: $success-color;
    border-color: $success-color;
  }
}

.btn-danger {
  background-color: $danger-color;
  border-color: $danger-color;
  color: #fff;

  &:hover {
    color: #fff;
    background-color: darken($danger-color, 8%);
    border-color: darken($danger-color, 8%);
  }
}

.btn-outline-danger {
  color: $danger-color;
  border-color: $danger-color;

  &:hover {
    color: #fff;
    background-color: $danger-color;
    border-color: $danger-color;
  }
}

.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown-menu {
  font-size: 0.8rem;
  border-radius: $border-radius;
  background: #fff;
  border-color: rgba($primary-text-color, 0.15);
}

.dropdown-item {
  padding: 0.6rem 1.5rem;
  color: $primary-text-color;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: $background-color;
  color: $primary-text-color;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: $primary-color;
}

.dropdown-divider {
  border-color: $separator-color;
}

.btn-xl,
.btn-group-xl>.btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 1rem 3.5rem 0.9rem;
}

.btn-lg,
.btn-group-lg>.btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 0.75rem 2.6rem 0.6rem 2.6rem;
}

.btn-sm,
.btn-group-sm>.btn {
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  line-height: 1.5;
}

.btn-xs,
.btn-group-xs>.btn {
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
}

.text-primary {
  color: $primary-color !important;
}

.text-success {
  color: $success-color !important;
}

.text-danger {
  color: $danger-color !important;
}

label {
  margin-bottom: 0;
}

.min-h-100 {
  min-height: 100%;
}

.loader {
  background-image: url("../images/loader.svg");
  height: 100%;
  top: 0;
  position: fixed;
  z-index: 1200;
  background-color: rgba(255, 255, 255, 0.7);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}

.progressBar {
  height: 20%;
  top: 40%;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.7);
  background-repeat: no-repeat;
  background-position: center;
  width: 20%;
  display: flex;
  left: 45%;
}

.page-size {
  color: #333;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  margin-top: 20px;

  button {
    border-color: $secondary-text-color;
    color: #333;
    font-size: 0.85rem;

    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
      color: #fff;
    }
  }
}

.dropzone {
  display: flex;
  justify-content: center;

  .fi {
    font-size: 30px;
    color: #828282;
  }

  .dropzone-thumbnail {
    img {
      object-fit: contain;
      width: 200px;
      height: 200px;
    }
  }

  label {
    color: #2f2f2f;
    font-size: 16px;
    line-height: 19px;
  }
}

.list-group .dropzone-thumbnail {
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 9px -5px black;
  margin: 8px auto;
  display: inline-flex;
  border-radius: $border-radius;

  &:hover .remove {
    opacity: 1;
  }

  img {
    padding: 6px;
    width: 200px;
    object-fit: contain;
  }

  span.MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-colorPrimary {
    top: 0;
    /* right: 0; */
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
    color: coral;
    background-color: aliceblue;
  }

  .remove {
    position: absolute;
    right: -35px;
    top: -35px;
    padding: 45px 45px 15px 15px;
    background: red;
    z-index: 1;
    border-radius: 50%;
    opacity: 0;
    transition: 0.3s;

    span {
      line-height: 0;
      cursor: pointer;
      width: 24px;
      color: white;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.alert-container {
  .swal2-popup {
    width: 350px;
    border-radius: 10px;
    padding: 22px;
  }

  .swal2-title {
    font-weight: 400;
    color: #2f2f2f;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
  }

  .swal2-html-container {
    font-weight: 400;
    color: #4f4f4f;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }

  .swal2-actions {
    justify-content: space-between;
  }

  .swal2-cancel {
    // padding: 0;
    font-weight: 400;
    // background: white !important;
    // color: #e85c64;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    margin-top: 50px;
  }

  button.swal2-cancel.swal2-styled {
    margin: 21px;
  }



  .swal2-confirm {
    // padding: 0;
    font-weight: 400;
    // background: white !important;
    // color: #5d4e91;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    // margin-top: 0px;
  }

  .swal2-icon-content {
    font-size: initial;
  }

  .swal2-success {
    width: 43px;
    height: 42px;
    color: #005288;
    border: none;

    .fi {
      font-size: 43px;
    }
  }

  .swal2-info {
    width: 38px;
    height: 38px;
    color: #e85c64;
    border: none;

    .fi {
      font-size: 38px;
    }
  }

  .swal2-icon-show {
    margin: 6px auto 22px;
  }
}



.invalid-feedback {
  display: block;
  font-size: 0.76rem;
  margin-bottom: -12px;
  margin-top: 2px;
  font-weight: initial;
}

.modal-content {
  border-radius: 10px;

  &.box-shadow {
    box-shadow: 0 2px 4px 0 #e0e0e0;
  }

  .modal-header {
    padding: 22px 27px 12px 81px;

    .modal-title {
      color: #2f2f2f;
      font-size: 22px;
      line-height: 26px;
      font-weight: 500;
    }

    .close {
      font-weight: 400;
    }
  }
}

/* status dropdown style */
select#exampleSelect {
  border-color: #212529;
  box-shadow: none !important;
  width: fit-content;
}