@import url("https://cdn.syncfusion.com/ej2/material.css");

.app-layout {
  span.no-data {
    margin: 0 auto;
    text-align: center;
    display: block;
  }

  > .sidebar {
    position: relative;
    z-index: 2;
    overflow-y: auto;
    height: 100vh;
    width: 240px;
    background: $primary-color;

    > .cross {
      position: absolute;
      right: 5%;
      top: 5px;
      font-size: 20px;
      color: gray;
      cursor: pointer;
    }

    > .cross:hover {
      font-size: 20px;
      color: rgba(12, 116, 185, 0.825);
    }

    > .header {
      height: $user-bar-height;
      background-color: $primary-dark-color;
      display: flex;

      a {
        margin: auto;
        margin-left: 50px;

        .logo {
          height: 64px;
          border-radius: 30px;
        }
      }
    }

    > .nav .nav-link {
      height: 50px;
      color: white;
      margin: 5px 0;

      &.active,
      &:hover {
        background-color: $secondary-color;
        color: black;
      }

      .fi {
        font-size: 24px;
      }

      .text {
        margin: auto 0 auto 12px;
        font-size: 15px;
        line-height: 18px;

        // color: #000;
        &:hover {
          color: black;
        }

        &:active {
          color: black;
        }
      }
    }

    & .user-profile {
      background-color: $primary-dark-color;
      padding: 13px 20px;
    }

    & .user-avatar {
      margin: 0 15px 0 0 !important;
      height: 44px !important;
      width: 44px !important;
      line-height: 44px;
    }

    .logo {
      width: 80px;
      height: 60px;
      justify-content: center;
      margin: 0 auto 10px auto;
    }
  }

  @media (max-width: 780px) {
    > .sidebar {
      position: absolute;
      visibility: hidden;
    }

    > .sidebar1 {
      visibility: visible !important;
    }
  }

  > .app-content {
    height: 100vh;

    @include respond-above(sm) {
      width: calc(96% - 205px);
    }

    > .user-bar {
      position: relative;
      z-index: 1;
      height: $user-bar-height;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 -8px 14px rgba(0, 0, 0, 0.65) !important;
      padding: 0 16px;
      flex-wrap: nowrap;

      .left {
        .handburger {
          font-size: 30px;
          margin: 24px;
          visibility: hidden;
        }

        .handburger:hover {
          font-size: 30px;
          margin: 24px;
          color: rgba(12, 116, 185, 0.825);
          cursor: pointer;
        }
      }

      .btn {
        border-radius: 50px;
        padding: 8px 30px 7px;
        font-size: 16px;
        line-height: 19px;
      }

      .right {
        margin-left: auto;
        display: flex;
        align-items: center;
        margin-right: 24px;

        .flaticon-bell {
          font-size: 30px;
          color: $primary-color;
          margin-right: 16px;
        }

        .badge {
          font-size: 11px;
          font-weight: 400;
          line-height: 13px;
          text-align: center;
          border-radius: 50%;
          position: absolute;
          left: 35%;
          top: 18%;
        }

        .flaticon-office-building {
          font-size: 30px;
          color: $primary-dark-color;
          margin: 0 16px;
        }

        .text {
          color: #333;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
        }

        .profile-text {
          color: #333;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          cursor: pointer;
        }
      }
    }

    @media (max-width: 770px) {
      > .user-bar {
        .left {
          .handburger {
            font-size: 30px;
            margin: 24px;
            visibility: visible;
          }

          .handburger:hover {
            font-size: 30px;
            margin: 24px;
            color: rgba(34, 129, 193, 0.825);
            cursor: pointer;
          }
        }
      }
    }

    > .content {
      height: calc(100vh - #{$user-bar-height});
      overflow-y: auto;

      .content-body {
        min-height: calc(100vh - #{$user-bar-height});

        .user-management,
        .subscription-management {
          padding: 15px 0px;

          .page-header {
            color: #000;
            font-size: 25px;
            line-height: 30px;
            font-weight: 500;
          }

          .sub-heading {
            color: #333;
            font-size: 16px;
            line-height: 19px;
          }

          .search-box {
            border: 1px solid #bdbdbd;
            border-radius: 10px;
            // margin: 6px auto 7px auto;
            margin-top: 10px;
            margin-left: 50px;

            .fi {
              font-size: 24px;
              margin: 6px 0px 1px 17px;
              color: #828282;
            }

            .form-control {
              border: none;
              margin: auto 0;
              width: calc(100% - 55px);
            }
          }
        }

        .add-client,
        .edit-client,
        .view-client,
        .start-service,
        .add-terminal {
          > .header {
            padding: 18px 33px;
            border-bottom: 1px solid $separator-color;

            > .page-header {
              color: #333;
              font-size: 21px;
              line-height: 25px;
              font-weight: 500;
            }

            .back-btn {
              display: inline-flex;
              color: #828282;
              font-size: 14px;
              text-decoration: none;
              align-items: center;

              .fi {
                font-size: 15px;
                margin-right: 5px;
              }
            }
          }

          .fields {
            margin: 40px 0 80px;
            padding: 50px 31px 70px;
            border-radius: 10px;
            background-color: #fff;
            box-shadow: 0 2px 4px 0 #e0e0e0;
          }
        }

        .view-client {
          .fields {
            padding: 39px 80px 30px;
          }
        }

        .client-invoice {
          > .header {
            padding: 18px 20px 16px 42px;
            border-bottom: 1px solid $separator-color;

            > .page-header {
              color: #333;
              font-size: 21px;
              line-height: 25px;
              font-weight: 500;
            }

            .back-btn {
              display: inline-flex;
              color: #828282;
              font-size: 14px;
              text-decoration: none;
              align-items: center;

              .fi {
                font-size: 15px;
                margin-right: 5px;
              }
            }
          }

          .fields {
            padding: 52px 42px 70px;
          }
        }

        .questionnaire-management,
        .privacy-policy,
        .terms-condition,
        .configuration,
        .invoices,
        .reports {
          padding: 38px 35px 80px 32px;

          .page-header {
            color: #000;
            font-size: 25px;
            line-height: 30px;
            font-weight: 500;
          }
        }

        .privacy-policy,
        .terms-condition {
          .editor {
            margin-top: 33px;
            border-radius: 10px;
            background-color: #fff;
            box-shadow: 0 2px 4px 0 #e0e0e0;
            padding: 61px 46px 89px 44px;

            .save-btn {
              margin-top: 73px;
            }
          }
        }

        .subscription-management {
          > .content {
            margin-top: 27px;
            border-radius: 10px;
            box-shadow: 0 2px 4px 0 #e0e0e0;

            > .header {
              border-radius: 10px 10px 0 0;
              background-color: $primary-color;
              font-size: 20px;
              line-height: 24px;
              color: #fff;
              font-weight: 500;
              padding: 18px 40px;
            }

            > .fields {
              padding: 45px 20px 50px;
            }
          }
        }

        .configuration {
          > .content {
            margin-top: 14px;
            border-radius: 10px;
            background-color: #fbfbfb;
            padding: 20px 36px;

            table {
              border-collapse: separate;
              border-spacing: 0 14px;

              thead {
                color: $secondary-color;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;

                td {
                  &.small {
                    font-size: 17px;
                    line-height: 20px;
                    font-weight: 400;
                  }

                  &:first-child {
                    width: 46%;
                  }

                  &:not(:first-child) {
                    text-align: center;
                  }

                  // &:last-child {
                  //   width: 16%;
                  // }
                }
              }

              tbody {
                font-size: 17px;
                line-height: 20px;

                tr {
                  margin-top: 10px;
                  padding: 11px 26px;

                  &.white {
                    border-radius: 5px;
                    background-color: #fff;
                    box-shadow: 0 2px 20px 0 #e0e0e0;

                    td {
                      border-radius: 5px;
                    }
                  }

                  td {
                    vertical-align: middle;
                    padding: 12px 20px;
                    position: relative;

                    &:first-child {
                      padding-left: 30px;
                    }

                    &:not(:first-child) {
                      text-align: center;
                    }

                    .dot {
                      width: 10px;
                      height: 10px;
                      top: calc(50% - 5px);
                      border-radius: 50px;
                      position: absolute;
                      left: 12px;
                      background-color: $primary-color;
                    }

                    .form-control {
                      width: 88%;

                      &:focus {
                        border-color: $primary-color;
                      }
                    }
                  }
                }
              }

              .flaticon-info {
                font-size: 15px;
                color: #828282;
                margin-left: 4px;
                margin-top: 3px;
              }

              td .days.form-control {
                margin: 0 8px;
                width: 38px;
                padding: 3px;
                height: 34px;
                text-align: center;
              }
            }
          }
        }

        td.text-right.text-nowrap.claim {
          display: flex;
          justify-content: center;
        }


        .invoices .collapse-container {
          margin-top: 44px;
        }

        .reports {
          .export-btn,
          .date-btn {
            display: flex;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            margin-left: 40px;

            .fi {
              font-size: 22px;
            }
          }
        }

        .collapse-container {
          background-color: $primary-color;
          border-radius: 10px;
          margin-bottom: 100px;

          &.no-header {
            background: none;
            margin-top: 10px;

            .card-list {
              border-radius: 20px;
              background-color: #fbfbfb;
              box-shadow: none;
            }
          }

          > .header {
            display: flex;
            width: 100%;
            color: #fff;
            font-size: 25px;
            font-weight: 500;
            line-height: 30px;
            // padding: 17px 35px;

            .flip {
              transform: rotate(180deg);
            }
          }

          .card-list {
            margin-top: 0;
            border-radius: 0 0 10px 10px;
            padding: 31px 43px 52px 50px;
            background-color: #f2f2f2;
            box-shadow: 0 2px 4px 0 #e0e0e0;

            .table {
              margin-top: 10px;
              border-collapse: separate;
              border-spacing: 0 14px;

              thead tr {
                font-size: 18px;
                line-height: 35px;
                color: white;
                background-color: $primary-color;
                text-align: center;

                td {
                  padding: 1px 1px !important;

                  &:first-child {
                    padding-left: 18px !important;
                  }

                  &:last-child {
                    width: 50px;
                    padding-right: 20px !important;
                  }
                }
              }

              tbody tr {
                margin-top: 14px;
                font-size: 17px;
                line-height: 23px;
                box-shadow: 0 2px 20px 0 #e0e0e0;
                border-radius: 10px;
                text-align: center;

                td {
                  padding: 21px 12px 20px !important;
                  color: #000;
                  /* background-color: white; */
                  padding: 29px 12px 25px;
                  vertical-align: middle;
                  max-width: 200px;

                  &:first-child {
                    border-radius: 10px 0 0 10px;
                    padding-left: 28px !important;
                  }

                  &:last-child {
                    border-radius: 0 10px 10px 0;
                    padding-right: 28px !important;
                  }

                  .fi.icon {
                    font-size: 30px;
                    margin-left: 8px;
                  }

                  button:not(.dropdown-item),
                  a:not(.dropdown-item) {
                    margin: 0 8px;
                    line-height: 0;
                    vertical-align: middle;
                    text-decoration: none;
                    display: inline-block;

                    &:last-child {
                      margin-right: 0;
                    }

                    .fi {
                      font-size: 20px;
                    }

                    &:first-child {
                      .fi {
                        font-size: 26px;
                      }
                    }
                  }

                  .dropdown-item {
                    display: flex;
                    font-size: 15px;
                    line-height: 20px;
                    color: #757575;

                    &:active {
                      .fi {
                        color: inherit !important;
                      }
                    }

                    a {
                      color: inherit;
                      text-decoration: none !important;
                    }

                    .fi {
                      font-size: 18px;
                    }
                  }

                  .menu-icon {
                    color: #757575;
                  }

                  span {
                    overflow: hidden;
                    word-wrap: break-word;
                  }
                }
              }
            }
          }
        }
      }

      .footer {
        justify-content: center;
      }
    }
  }
}

.radio-btns {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}

.radio-btn > label {
  margin-left: 8px;
  cursor: pointer;
}

input[type="radio"] {
  accent-color: #6c757d;
}

.action-btns {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.action-btn {
  background-color: #425067;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.action-btn:hover {
  background-color: #2f394991;
  transition: 0.3s;
}

.footer {
  height: $footer-height;
  background-color: $primary-color;
  box-shadow: 0 2px 0 0 #f2f2f2;
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  // width: 100%;
  justify-content: space-between;
  align-items: center;
  // position: absolute;

  .links a {
    margin: 0 18px;
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
  }
}

// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

// * {
// 	margin: 0;
// 	padding: 0;
// 	box-sizing: border-box;
// }
// *::after,
// *::before {
// 	margin: 0;
// 	padding: 0;
// 	box-sizing: border-box;
// }

// body {
// 	font-family: 'Poppins', sans-serif;
// 	font-weight: 400;
// }

// .container {
// 	/* margin: 0 auto; */
// }

.navigation-container {
  width: 1250px;
  margin: 0 auto;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation-container .nav-logo-box span {
  font-size: 1.3rem;
  font-weight: 600;
  color: #425067;
}

.navigation-container .navbar {
  display: flex;
}

.navigation-container .navbar a {
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  color: rgb(123, 123, 123);
  margin: 0 1rem;
}

.footer.dash-rendered {
  background-color: rgb(250, 250, 250);
  border-top: 2px solid rgb(210, 210, 210);
  color: black;
}

.footer .footer-section1 {
  width: 1250px;
  padding: 5rem 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.footer-section1 .footer-company-info {
  width: 500px;
}

.footer-section1 .footer-company-info .footer-company-logo {
  font-size: 1.2rem;
  font-weight: 500;
}

.footer-section1 .footer-company-info .footer-company-details {
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 300;
  line-height: 1.5;
}

.footer-section1 .footer-resource-link {
  display: flex;
  flex-direction: column;
}

.footer-section1 .footer-resource-link a {
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  color: white;
  display: inline-block;
  margin: 0.3rem 0;
  padding: 0.3rem 0;
}

.footer.dash-rendered .footer-section1 .footer-resource-link a {
  color: rgb(123, 123, 123);
}

.footer-section1 .footer-social-links {
  display: flex;
  align-items: flex-end;
}

.footer-section1 .footer-social-links a {
  text-decoration: none;
  font-size: 1.5rem;
  display: inline-block;
  margin: 0 0.5rem;
  color: white;
}

.footer.dash-rendered .footer-section1 .footer-social-links a {
  color: #425067;
}

.footer .footer-section2 {
  width: 1250px;
  margin: 0 auto;
  padding: 3rem 0 1rem 0;
}

.footer-section2 .copyright-title {
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 0.85rem;
  font-weight: 300;
}

.footer-section2 .copyright-title span {
  display: inline-block;
  margin: 0.3rem 0;
}

/* LOGIN FORM STYLE */
.login-container {
  width: 500px;
  margin: 100px auto 0 auto;
  border-radius: 0.3rem;
  padding: 2.5rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.login-container h2 {
  font-size: 2rem;
  font-weight: bold;
}

.login-form {
  margin-top: 2rem;
}

.login-form .field-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.field-group label {
  display: block;
  font-weight: 500;
  margin-bottom: 0.3rem;
}

.field-group input {
  display: block;
  font-size: 1rem;
  width: 100%;
  height: 40px;
  outline: none;
  border: 1px solid rgb(194, 194, 194);
  border-radius: 0.2rem;
  padding: 0 1rem;
}

.forgot-pass-block {
  display: flex;
  justify-content: flex-end;
}

#fixed {
  margin-left: 13px;
}

.forgot-pass-block .forgot-pass-link {
  text-decoration: none;
  display: inline-block;
  color: #425067;
  font-weight: 600;
}

.login-form .login-btn {
  margin-top: 3rem;
  text-decoration: none;
  display: block;
  width: 100%;
  padding: 0.7rem 0;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: #425067;
  color: white;
  text-align: center;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.login-btn:hover {
  background-color: #2c3545;
}

.sign-up-block {
  margin-top: 2rem;
  font-weight: 400;
  color: rgb(123, 123, 123);
  text-align: center;
}

.sign-up-block a {
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  color: #425067;
  margin-left: 0.3rem;
}

.error-msg {
  font-size: 0.9rem;
  font-weight: 600;
  color: rgb(222, 19, 19);
  margin-top: 0.3rem;
}

/* SIGN UP FORM */
.sign-up-container {
  width: 500px;
  margin: 100px auto 0 auto;
  padding: 2.5rem;
  box-shadow: rgba(17, 12, 46, 0.078) 0px 48px 100px 0px;
}

.sign-up-container h2 {
  font-size: 2rem;
  color: #425067;
  font-weight: 600;
}

.sign-up-form {
  margin-top: 2rem;
}

.sign-up-form .field-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.sign-up-form .sign-up-btn {
  margin-top: 3rem;
  display: block;
  width: 100%;
  padding: 0.7rem 0;
  border: none;
  font-family: "Inter", sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: #3ca165;
  color: white;
  text-align: center;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.sign-up-btn:hover {
  background-color: #398758;
}

.log-in-block {
  margin-top: 2rem;
  font-weight: 400;
  color: rgb(123, 123, 123);
  text-align: center;
}

.log-in-block a {
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  color: #3ca165;
  margin-left: 0.3rem;
}

/* DASHBOARD-SIDEBAR */

.dashboard {
  width: 100%;
}

.dashboard .wrapper {
  display: flex;
}

.dashboard .sidebar-container {
  width: 300px;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background-color: #425067;
  color: white;
  position: sticky;
  top: 0;
}

.sidebar-container .sidebar-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-container .nav-container {
  display: flex;
  margin-top: 5rem;
  flex-direction: column;
}

.sidebar-container .nav-container a {
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
  width: max-content;
  color: white;
  display: inline-block;
  margin: 0.7rem 0;
  padding: 0.4rem 0;
  transition: 0.1s ease-in-out;
}

.sidebar-container .nav-container a.active {
  border-bottom: 2px solid rgba(255, 255, 255, 0.637);
  color: rgba(255, 255, 255, 0.637);
}

.sidebar-container .nav-container a i {
  display: inline-block;
  margin-right: 0.5rem;
}

.sidebar-container .nav-container a:hover {
  color: rgba(255, 255, 255, 0.637);
}

.logout-btn-block button {
  display: block;
  width: 100%;
  border: none;
  padding: 0.5rem 1.2rem;
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  background-color: #828899;
  color: white;
  border-radius: 0.2rem;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.logout-btn-block button:hover {
  background-color: #6a6f7c;
}

/* DASHBOARD-INFO-BLOCK */
.dashboard .admin-info-container {
  padding: 1rem 3rem;
  width: 100%;
  background-color: rgb(250, 250, 250);
}

.admin-info-container .logo-box {
  font-size: 1.2rem;
  font-weight: 500;
}

.admin-info-container .admin-info-section {
  margin-top: 3rem;
}

/* SURVEYERS */
.surveyers-info-container {
  display: flex;
  flex-direction: column;
}

.surveyers-info-groups .surveyers-tab {
  display: flex;
  border-bottom: 2px solid #425067;
}

.surveyers-tab span {
  cursor: pointer;
  padding: 0.6rem 1rem;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.85rem;
}

.surveyers-tab span.active-table {
  color: white;
  background-color: #425067;
}

.user-info-container {
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;
  border-radius: 0.3rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  background-color: white;
}

.user-info-container th {
  width: 25%;
}

.user-info-container thead tr {
  background-color: #343539;
  color: white;
  text-align: left;
  font-weight: bold;
  font-size: 1.1rem;
}

.user-info-container th,
.user-info-container td {
  padding: 0.5rem 1.5rem;
  font-size: 1.2rem;
}

.user-info-container th {
  padding: 0.8rem 1.5rem;
}

.user-info-container tbody tr {
  border-bottom: 1px solid #dddddd;
}

.user-info-container tbody tr td.action-btns button {
  outline: none;
  border: none;
  display: inline-block;
  margin-right: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.6rem 1rem;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: white;
  font-size: 1rem;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

td.action-btns .approve-btn,
td.action-btns .enable-btn {
  background-color: #808490;
}

td.action-btns .reject-btn,
td.action-btns .disable-btn {
  background-color: #425067;
}

td.action-btns .approve-btn:hover,
td.action-btns .enable-btn:hover {
  background-color: #676a74;
}

td.action-btns .reject-btn:hover,
td.action-btns .disable-btn:hover {
  background-color: #2e3746;
}

#d-projects-section,
#d-suggestions-section {
  display: none;
}

.user-name-link {
  cursor: pointer;
}

/* USER INFO STYLE */
.surveyer-details-block {
}

.surveyer-details {
  display: flex;
  margin: 3rem auto 0 auto;
  width: 60%;
}

.surveyer-details .surveyer-details-img {
  width: 30%;
}

.surveyer-details .surveyer-details-img img {
  width: 100%;
  border-radius: 0.3rem;
}

.surveyer-details .surveyer-other-details {
  margin-left: 5rem;
}

.surveyer-other-details p {
  font-size: 1.1rem;
  margin-top: 1rem;
  font-weight: 600;
}

.surveyer-other-details p:first-child {
  margin-top: 0;
}

.surveyer-other-details p span {
  display: inline-block;
  margin-left: 0.5rem;
  font-weight: 500;
  color: rgb(123, 123, 123);
}

/* PROJECTS */
.projects-info-container {
  margin-top: 1.5rem;
  padding: 0 0.5rem;
}

.projects-info-group .heading-and-add-group {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid rgb(210, 210, 210);
  padding-bottom: 0.8rem;
  margin: 0 3rem;
  align-items: center;
}

.projects-info-group-heading {
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 600;
}

.heading-and-add-group .create-proj-btn {
  border: none;
  outline: none;
  background-color: #808490;
  color: white;
  padding: 0.6rem 1.2rem;
  border-radius: 0.2rem;
  cursor: pointer;
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  transition: 0.2s ease-in-out;
}

.heading-and-add-group .create-proj-btn:hover {
  background-color: #676a74;
}

.projects-list-container {
}

.projects-list-container .projects-list-table {
  margin: 0 auto;
  border-collapse: collapse;
  border-radius: 0.3rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  // overflow: hidden;
}

.projects-list-table th {
  width: 18%;
}

.projects-list-table .action-group {
  width: 10%;
}

.projects-list-table .action-group button:last-child {
  margin: 0 1rem;
}

.projects-list-table th,
.projects-list-table td {
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
}

.projects-list-table td {
  padding: 1rem 2rem;
}

.projects-list-table thead tr {
  background-color: #425067;
  color: white;
  text-align: left;
  font-weight: bold;
  font-size: 1.1rem;
}

.projects-list-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.project-status span {
  display: inline-block;
  width: 100px;
  text-align: center;
  padding: 0.5rem 1.2rem;
  border-radius: 1.5rem;
  font-size: 0.9rem;
  font-weight: 600;
}

.project-status span.active {
  color: $secondary-text-color;
}

.project-status span.inactive {
  color: $danger-color;
}

.projects-list-table .actions-group {
  position: relative;
}

.actions-group .actions-dropdown {
  position: absolute;
  left: 0;
  top: 80%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.256);
  border-radius: 0.3rem;
  color: $primary-color;
  height: max-content;
  z-index: 9999;
  padding: 0.3rem 0;
}

.actions-dropdown span {
  border-bottom: 1px solid $separator-color;
  font-size: 0.85rem;
  padding: 0.5rem 0.8rem;
  font-weight: 600;
}

.actions-dropdown span:last-child {
  border: none;
}

/* CREATE PROJECT MODAL */
.create-project-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.622);
  left: 0;
  z-index: 999999;
}

.create-project-modal .create-project-modal-content {
  background-color: white;
  padding: 2.5rem;
  border-radius: 0.3rem;
  position: relative;
}

.create-project-modal-content .create-proj-close-btn {
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
  top: 1.5rem;
  font-size: 1.8rem;
  padding: 0.3rem;
}

.create-project-modal-content .modal-content-heading {
  font-size: 1.6rem;
  font-weight: 600;
}

.create-project-modal-content .create-project-form {
  width: 420px;
  margin-top: 2rem;
}

.date-picker {
  width: 100%;
  height: 40px;
}

.create-project-form .field-group {
  margin-bottom: 1rem;
}

.create-project-form button {
  width: 100%;
  display: block;
  margin-top: 2rem;
  padding: 0.8rem 0;
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  border: none;
  outline: none;
  font-weight: 500;
  background-color: #425067;
  color: white;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.create-project-form button:hover {
  background-color: #2f3949;
}

/* VIEW PROJECT DATA PAGE */
.project-page {
  margin-top: 1.5rem;
}

.project-page .project-page-tabs {
  display: flex;
  border-bottom: 2px solid #425067;
}

.project-page-tabs span {
  cursor: pointer;
  padding: 0.6rem 1rem;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.85rem;
}

.project-page-tabs span.activeTable {
  color: white;
  background-color: #425067;
}

.project-tab-info-container {
  margin-top: 2rem;
}

/* PROJECT DATA */
.project-details-container {
  margin-top: 2rem;
}

.project-details-container .project-details-block {
  width: 550px;
  margin: 0 auto;
  background-color: white;
  padding: 2rem;
  border-radius: 0.3rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.project-details-block .project-details-sub-block {
  display: flex;
  flex-direction: column;
  /* width: max-content;
	margin: 0 auto; */
}

.project-details-sub-block span {
  margin: 0.5rem 0;
  font-size: 1.2rem;
}

.project-details-block .project-btns-block {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
}

.project-details-sub-block .field-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.project-details-sub-block .field-group label {
  font-weight: 600;
}

.project-btns-block button {
  padding: 0.6rem 1.2rem;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  border-radius: 0.2rem;
  cursor: pointer;
}

.project-btns-block-cancel {
  background-color: #808490;
}

.project-btns-block-update {
  background-color: #425067;
}

.project-details-container .assign-surveyor-block {
  margin-top: 4rem;
}

.assign-surveyor-heading-add-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgb(210, 210, 210);
  padding-bottom: 0.8rem;
}

.assign-surveyor-heading-add-block .assigned-surveyor-heading {
  font-size: 1.5rem;
  font-weight: 600;
}

.assign-surveyor-heading-add-block .add-surveyor-btn {
  border: none;
  outline: none;
  background-color: rgb(58, 123, 222);
  color: white;
  padding: 0.6rem 1.2rem;
  border-radius: 0.2rem;
  cursor: pointer;
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  transition: 0.2s ease-in-out;
}

.assign-surveyor-heading-add-block .add-surveyor-btn:hover {
  background-color: rgb(49, 97, 169);
}

.assign-surveyor-sub-block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

.assign-surveyor-sub-block .assign-surveyor-sub-block-cell {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  padding: 2.5rem;
  margin-top: 2rem;
  border-radius: 0.3rem;
  background-color: white;
}

.assign-surveyor-sub-block .assigned-surveyor-info {
  display: flex;
  flex-direction: column;
}

.assigned-surveyor-info span {
  margin: 0.5rem 0;
  font-size: 1.2rem;
}

.assign-surveyor-sub-block .remove-surveyor {
  margin-top: 2rem;
}

.assign-surveyor-sub-block .remove-surveyor button {
  padding: 0.6rem 1.2rem;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  border-radius: 0.2rem;
  background-color: rgb(207, 90, 77);
  cursor: pointer;
}

/* ASSIGN SURVEYOR MODAL */
.add-surveyor-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.622);
  left: 0;
}

.add-surveyor-modal .add-surveyor-modal-content {
  background-color: white;
  padding: 2.5rem;
  border-radius: 0.3rem;
  position: relative;
}

.add-surveyor-modal-content .add-surveyor-close-btn {
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
  top: 1.5rem;
  font-size: 1.8rem;
  padding: 0.3rem;
}

.add-surveyor-modal-content .modal-content-heading {
  font-size: 1.6rem;
  font-weight: 600;
}

.add-surveyor-modal-content .add-surveyor-form {
  width: 350px;
  margin-top: 2rem;
}

.add-surveyor-form .field-group {
  margin-bottom: 1rem;
}

.add-surveyor-form button {
  width: 100%;
  display: block;
  margin-top: 2rem;
  padding: 0.8rem 0;
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  border: none;
  outline: none;
  font-weight: 500;
  background-color: rgb(58, 123, 222);
  color: white;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.add-surveyor-form button:hover {
  background-color: rgb(49, 97, 169);
}

.survey-forms-container {
  margin-top: 1rem;
}

.switch-survey-forms {
  display: flex;
  gap: 1rem;
  padding: 0 3rem;
}

.switch-survey-forms button {
  padding: 0.5rem 1.2rem;
  font-size: 0.9rem;
  cursor: pointer;
  color: white;
  border: none;
  outline: none;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  border-radius: 0.3rem;
}

.switch-survey-forms button.form-btn-conventional {
  background-color: #425067;
}

.switch-survey-forms button.form-btn-rtk {
  background-color: #425067;
}

.survey-form-block .survey-form-block-heading {
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.survey-forms-container .survey-forms {
  margin-top: 2rem;
}

/* CONVENTIONAL TABLE */
.survey-form-block {
  margin: 2rem auto;
  width: 100%;
  border-radius: 0.3rem;
}

.survey-forms-table {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
}

.survey-forms-table thead tr th:first-child {
  border-top-left-radius: 0.3rem;
}

.survey-forms-table thead tr th:last-child {
  border-top-right-radius: 0.3rem;
}

.survey-forms-table th {
  padding: 1rem 0 1rem 1.2rem;
  font-size: 1.2rem;
  text-align: left;
  background-color: #122c34;
  color: white;
  width: 12%;
}

.survey-forms-table tbody tr {
  border: 1px solid rgb(210, 210, 210);
  border-top: none;
}

.survey-forms-table td {
  padding: 1rem 0 1rem 1.2rem;
  font-size: 1.2rem;
}

.survey-forms-table .action-group {
  width: 28% !important;
}

.survey-forms-table .survey-forms-table-action {
  display: flex;
  gap: 1.5rem;
}

.survey-forms-table-action button {
  color: white;
  border: none;
  outline: none;
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 500;
  border-radius: 1.5rem;
}

.survey-forms-table-action .con-view-details-btn {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.survey-forms-table-action .con-view-notes-btn {
  background-color: #425067;
}

/* CONVENTIONAL FORM DETAILS */
.conventional-form-details {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.546);
}

.conventional-form-details .modal-content {
  background-color: white;
  width: max-content;
  margin: 100px auto;
  border-radius: 0.3rem;
  height: 80vh;
  /* overflow: scroll; */
  overflow: auto;
  width: 800px;
  position: relative;
}

#downloadable-content {
  padding: 3rem 3rem 1rem 3rem;
}

.conventional-form-details .modal-content .close-con-btn {
  position: absolute;
  right: 1.2rem;
  top: 1.2rem;
  font-size: 1.8rem;
  padding: 0.3rem;
  cursor: pointer;
}

.modal-content-heading {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
}

.conventional-form-table1,
.conventional-form-table2 {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}

.conventional-form-table1 th {
  width: 25%;
  padding: 0.5rem;
  text-align: left;
}

.table-section-details {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.single-section-details {
  font-weight: 600;
  padding: 0.5rem;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.conventional-form-table2 th {
  text-align: center;
  padding: 0.5rem;
  width: 20%;
}

.conventional-form-table2 .description-section {
  width: 60%;
}

.conventional-form-table2 td {
  text-align: center;
  padding: 0.5rem;
}

.form-group {
  display: flex;
  gap: 1.5rem;
  margin-top: 0.5rem;
}

.form-group-three {
  display: flex;
  gap: 1.5rem;
  margin-top: 0.5rem;
}

.form-group-three .form-sub-group {
  width: 33%;
}

.form-sub-group-img {
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: space-between;
}

.form-sub-group-img img {
  width: 200px;
  height: 250px;
}

.sub-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

#bottomantena {
  margin-right: 100px;
  width: 20px;
}

.form-group-three .form-sub-group input {
  width: 100%;
  display: block;
  height: 40px;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 0.2rem;
  outline: none;
  padding: 0 1rem;
  margin-top: 0.3rem;
  font-size: 1rem;
}

.form-group-single-row .form-sub-group {
  width: 100%;
  margin-top: 0.5rem;
}

.form-group-single-row .form-sub-group label {
  font-weight: 500;
}

.form-group-single-row .form-sub-group input {
  width: 100%;
  display: block;
  height: 40px;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 0.2rem;
  outline: none;
  padding: 0 1rem;
  margin-top: 0.3rem;
  font-size: 1rem;
}

.form-group-points {
  margin-top: 1rem;
}

.form-group-wrapper-heading {
  font-weight: 500;
}

.form-group-points .form-group-wrapper {
  margin-top: 1rem;
  /* border: 1px solid red;
	padding: 1rem; */
}

.form-group-wrapper .form-group-item {
  border: 1px solid rgb(123, 123, 123);
  padding: 1rem;
  border-radius: 0.3rem;
}

.form-group-item .form-sub-group-wrapper {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.form-group-item .form-sub-group-wrapper .form-sub-group {
  width: 50%;
}

.form-group-item input {
  width: 100%;
  display: block;
  height: 40px;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 0.2rem;
  outline: none;
  padding: 0 1rem;
  font-size: 1rem;
  margin-top: 0.3rem;
}

.form-group-item .form-sub-group-single textarea {
  resize: none;
  margin-top: 0.3rem;
  width: 100%;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 0.2rem;
  padding: 1rem;
  font-size: 1rem;
  outline: none;
  font-family: "Inter", sans-serif;
}

/* RTK TABLE */
.rtk-form-table-heading {
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.rtk-form-table {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
  border-radius: 0.2rem;
}

.rtk-form-table thead tr th:first-child {
  border-top-left-radius: 0.3rem;
}

.rtk-form-table thead tr th:last-child {
  border-top-right-radius: 0.3rem;
}

.rtk-form-table th {
  padding: 1rem 0 0.8rem 1.2rem;
  font-size: 1.2rem;
  text-align: left;
  background-color: #122c34;
  color: white;
  width: 12%;
}

.rtk-form-table tbody tr {
  border: 1px solid rgb(210, 210, 210);
  border-top: none;
}

.orthometric-gap {
  margin-left: -55px;
}

.rtk-form-table td {
  padding: 1rem 0 1rem 1.2rem;
  font-size: 1.2rem;
}

.rtk-form-table .action-group {
  width: 28% !important;
}

.rtk-form-table .rtk-table-actions {
  display: flex;
  gap: 1.5rem;
}

.rtk-table-actions button {
  color: white;
  border: none;
  outline: none;
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 500;
  border-radius: 1.5rem;
}

.rtk-table-actions .view-details-btn {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.rtk-table-actions .view-notes-btn {
  background-color: #425067;
}

/* RTK FORM DETAILS */
.rtk-form-details {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.546);
}

.rtk-form-details-content {
  background-color: white;
  width: max-content;
  margin: 100px auto;
  border-radius: 0.3rem;
  height: 80vh;
  overflow: auto;
  width: auto;
  position: relative;
}

.rtk-form-details-content .close-rtk-btn {
  position: absolute;
  right: 1.2rem;
  top: 1.2rem;
  font-size: 1.8rem;
  padding: 0.3rem;
  cursor: pointer;
}

.rtk-form-details-heading {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
}

.form-group .form-sub-group {
  width: 50%;
}

.form-group input {
  width: 100%;
  margin-top: 3px;
  display: block;
  height: 40px;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 0.2rem;
  outline: none;
  padding: 0.3rem 1rem;
  font-size: 1rem;
}

.form-group label {
  font-size: 1rem;
  display: inline-block;
  margin-bottom: 0.3rem;
  font-weight: 500;
}

.form-group .form-sub-group-three {
  width: 50%;
  display: flex;
  gap: 1rem;
}

.form-sub-group-three label {
  display: flex;
  align-items: center;
}

.form-sub-group-three img {
  display: inline-block;
  margin-right: 0.2rem;
}

.form-sub-group-three .form-sub-group {
  width: 50%;
}

.form-group-checkbox {
  margin-top: 1rem;
}

.form-group-checkbox label {
  font-weight: 500;
}

.form-group-checkbox .checkbox-rev {
  display: flex;
  margin-top: 0.3rem;
  justify-content: space-between;
}

.checkbox-rev input {
  display: inline-block;
  margin-right: 0.3rem;
}

.form-group-zone {
  margin-top: 1rem;
}

.form-group-zone input {
  width: 100%;
  display: block;
  height: 40px;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 0.2rem;
  outline: none;
  margin-top: 0.3rem;
  padding: 0 1rem;
  font-size: 1rem;
}

.form-group-coordinate {
  margin-top: 1.5rem;
}

.form-group-coordinate .form-sub-group {
  width: 33%;
}

.form-group-coordinate .coordinate-heading {
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  display: block;
  margin-bottom: 1.5rem;
}

.form-group-coordinate .coordinate-wrapper {
  display: flex;
  gap: 1.5rem;
}

.form-group-coordinate .form-sub-group input {
  width: 100%;
  display: block;
  height: 40px;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 0.2rem;
  outline: none;
  margin-top: 0.3rem;
  padding: 0 1rem;
  font-size: 1rem;
}

.checkbox {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.form-sub-group {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.form-sub-group > label {
  width: 50%;
}

.checkbox input {
  display: inline-block;
  margin-left: 0.3rem;
}

.form-group.form-group-block2 {
  margin-top: 2rem;
}

.form-group-tape {
  margin-top: 1rem;
}

.form-group-tape input {
  width: 100%;
  display: block;
  height: 40px;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 0.2rem;
  outline: none;
  margin-top: 0.3rem;
  padding: 0 1rem;
  font-size: 1rem;
}

.checkbox-item-scalefactor {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.checkbox-item-scalefactor input {
  margin-bottom: 6px;
}

#scalefactor {
  width: 20px;
  font-weight: 900;
}

#grid {
  width: 18px;
}

#ground {
  width: 18px;
}

#grid-r {
  width: 50%;
  height: 15px;
}

#ground-r {
  width: 50%;
  height: 15px;
}

.checkbox-single-items {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}

.tappdBaseHeight {
  margin-bottom: -25px;
}

.html2pdf__page-break {
  page-break-before: always;
}

.checkbox-single-items .img-block {
  margin-left: 3rem;
  margin-bottom: 22px;
}

.checkbox-single-items .img-block img {
  width: 130px;
}

.checkbox-single-items .checkbox-item {
  margin-top: 0.5rem;
  position: relative;
}

.checkbox-item img {
  position: absolute;
  top: 50%;
  right: -2.5rem;
  transform: translateY(-50%);
}

.checkbox-item > b {
  margin-right: 20px;
}

#setupPointName {
  margin-right: 12px;
}

.summary {
  position: absolute;
  background-color: #425067;
  padding: 10px;
  border-radius: 10px;
  color: white;
  top: 40%;
  left: 20%;
}

// #utm {
//   margin-left: 120px;
// }
// #local {
//   margin-left: 10px;
// }
// #other {
//   margin-left: 10px;
// }
.baseposition-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-right: 290px;
}

.baseposition-items1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.baseposition-items1 label {
  margin-top: 6px;
}

.checkbox-single-items .checkbox-item input {
  margin-right: 0.5rem;
}

.setupNotes-block {
  margin-top: 2rem;
}

.setupNotes-block textarea {
  resize: none;
  width: 100%;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 0.2rem;
  padding: 1rem;
  font-size: 1rem;
  outline: none;
  font-family: "Inter", sans-serif;
}

.button-block {
  padding: 2rem;
}

.button-block .pdf-btn {
  width: 50%;
  display: block;
  margin: 0 auto;
  padding: 0.8rem;
  border-radius: 2rem;
  background-color: rgb(90, 179, 111);
  font-size: 1.1rem;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
}

.button-block .pdf-btn {
  background-color: #425067;
  margin-top: 1rem;
}

#assistent {
  margin-right: 10px;
}

/* FIELD NOTES */
.field-note-modal {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.546);
}

#elevation-c {
  margin-right: 10px;
}

#jobNo-c {
  margin-right: 10px;
}

#pointName-r {
  margin-right: 10px;
}

#elevation-r {
  margin-right: 10px;
}

.field-note-modal-content {
  background-color: white;
  width: max-content;
  margin: 200px auto;
  border-radius: 0.3rem;
  overflow: auto;
  width: 600px;
  position: relative;
}

.field-note-modal-content .close-note-btn {
  position: absolute;
  right: 1.2rem;
  top: 1.2rem;
  font-size: 1.8rem;
  padding: 0.3rem;
  cursor: pointer;
}

.field-note-heading {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  padding: 1.5rem;
  border-bottom: 1px solid rgb(210, 210, 210);
}

.field-note-modal-content .field-note-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  font-weight: 500;
  font-size: 1.1rem;
}

.field-note-modal-content .field-note-text {
  padding: 2rem;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 1.4;
}

/* input box custom css */
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: $secondary-text-color;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

#logo {
  height: 100vh;
}

/*signup styles */

.signup {
  img {
    width: 100%;
    height: 100vh;
  }

  img.logo {
    width: 70px;
    height: 70px;
    margin: 0 auto 30px auto;
    float: none;
    display: block;
  }

  .col-form {
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    width: 400px;

    h4 {
      text-align: center;
      font-weight: bold;
      line-height: 5px;
    }

    p {
      text-align: center;
    }

    strong {
      color: #144ec7;
      cursor: pointer;
      margin-left: 10px;
      font-weight: bold;
    }
  }
  .col-form-resend {
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    width: 450px;

    h4 {
      text-align: center;
      font-weight: bold;
      line-height: 5px;
    }

    p {
      text-align: center;
    }

    strong {
      color: #144ec7;
      cursor: pointer;
      margin-left: 10px;
      font-weight: bold;
    }
  }
}

/* otp screen */
.otpContainer {
  margin: 5% auto;
  display: flex;
  justify-content: center;
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.output-group {
  align-items: center;
  justify-content: center;
  // margin-left: -1rem;
}

.tabnav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  align-items: center;
  margin-left: 50px;
}

.tablink {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

// .admin-model {
//   height: 459px;
// }
.addproject-form {
  display: flex;
  align-items: center;
  border: 1px solid #425067;
  border-radius: 5px;
  margin-top: 18px;
  flex-direction: column;
  // padding-bottom: 20;
  padding: 10px;
  // margin-bottom: 20px;

  // padding: 40;
}

.filtermonth-model {
  height: 240px;
  width: 300px;
}

.filterdate-model {
  height: 400px;
  width: 390px;
}

.rdtPicker {
  width: 100%;
}

.keycontacts {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  border: 1px solid #425067;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
}

.keycontacts .left {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 50%;
  gap: 10px;
}

.editinput {
  height: 25px;
  border: 2px solid #bec2cb;
  border-radius: 5px;
}

.keycontacts-fields {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
}

.keycontacts-fields :nth-child(1) {
  width: 20%;
}

.keycontacts-close {
  // position: absolute;
  color: #425067;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}

.project-model {
  position: relative;
  height: 750px;
  // height: 360px;
  width: 100%;
  overflow: hidden;
}

.project-add-btn {
  position: absolute;
  bottom: 35px;
  text-align: center;
  right: 1%;
}

.formik-field-height {
  height: 65px;
}

// .terminals-modal {
// 	max-width: 805px;

// 	.modal-header {
// 	  padding: 17px 28px 12px 28px;

// 	  .modal-title {
// 		font-weight: 400 !important;
// 	  }
// 	}

// 	.modal-body {
// 	  padding: 22px;
// 	}

// 	.btn {
// 	  width: 142px;
// 	  font-size: 16px;
// 	  line-height: 19px;
// 	}
//   }

/* green indicator */
.status-success {
  text-transform: capitalize;
  align-items: center;

  &:before {
    content: "";
    background-color: #425067;
    padding: 7px;
    display: inline-block;
    border-radius: 50px;
    margin-right: 0.4em;
  }

  &.Active,
  &.paid {
    &:before {
      background-color: #6d767c;
    }
  }

  &.pending:before {
    background-color: #fa9917;
  }

  &.sent:before {
    background-color: #f3ca3e;
  }
}

/* pending indicator */
.status-pending {
  text-transform: capitalize;
  align-items: center;

  &:before {
    content: "";
    background-color: #fa9917;
    padding: 7px;
    display: inline-block;
    border-radius: 50px;
    margin-right: 0.4em;
  }

  &.Active,
  &.paid {
    &:before {
      background-color: #6d767c;
    }
  }

  &.pending:before {
    background-color: #fa9917;
  }

  &.sent:before {
    background-color: #f3ca3e;
  }
}

/* rejected indicator */
.status-error {
  text-transform: capitalize;
  align-items: center;

  &:before {
    content: "";
    background-color: red;
    padding: 7px;
    display: inline-block;
    border-radius: 50px;
    margin-right: 0.4em;
  }

  &.Active,
  &.paid {
    &:before {
      background-color: #6d767c;
    }
  }

  &.pending:before {
    background-color: #fa9917;
  }

  &.sent:before {
    background-color: #f3ca3e;
  }
}

img.super-account {
  height: 50px;
}

.dropdown-menu.show {
  margin-left: -60px;
}

span.dropdown-item {
  cursor: pointer;
}

span#logout {
  background-color: red;
  color: white;
}

span.course-back {
  text-decoration: underline;
  color: #005288;
  cursor: pointer;
}

// .dropdown-menu {
// 	margin-right: 100px;
// 	float: left;
// }
.date-filter-head {
  text-align: center;
  padding: 30px 0;
  margin: 0 45px;
  border-radius: 10px;
  background-color: #f2f2f2;
  box-shadow: 0 2px 4px 0 hsl(0, 0%, 88%);
}

.rdt.from {
  width: 250px;
}

.date-filter-head h2 {
  font-size: 18px;
  padding: 10px 0 0;
  font-weight: bold;
}

/* from to lable */
span.mr-auto {
  text-align: initial;
}

.date-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 35px 30px;
  padding: 0px;
  width: 100%;
}

.date-filter input {
  outline: none;
  border: 1px solid #c9c9c9;
  padding: 2px 10px;
  border-radius: 4px;
  // margin: 0 21px;
  width: 200px;
}

.date-filter div {
  align-items: center;
}

.date-filter h5 {
  margin-bottom: 0px;
  font-size: 16px;
  float: left;
}

.suggestion-details-head,
.change-password-box {
  margin: 25px 40px;
  border-radius: 0 0 10px 10px;
  padding: 31px 43px 52px 50px;
  background-color: #f2f2f2;
  box-shadow: 0 2px 4px 0 #e0e0e0;
}

.suggestions-radio-details div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 2rem;
}

.react-datepicker-wrapper {
  width: auto !important;
}

.react-datepicker__day--selected {
  background-color: #425067 !important;
}

/* toggle button component */
.toggle-container {
  width: 55px;
  background-color: #c4c4c4;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  padding: 2px;
  height: 26px;
  position: relative;
  margin: auto;
  display: flex;
  align-items: center;
}

.view-model {
  width: 100%;
  height: 100vh;
  position: absolute;
}

.dialog-button {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  cursor: pointer;
  background-color: $primary-color;
  color: white;
  padding: 4px 12px;
  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  min-width: unset;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 24px;
  transition: all 0.3s ease;
}

.disabled {
  background-color: #707070;
  left: 2px;
}

// Change Password CSS

.change-password-box {
  padding: 6rem;
}

.change-password-box div div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.change-password-box div div label {
  width: 17%;
  text-align: left;
}

.scale-factor {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.suggestions-text-details input {
  width: 50%;
}

.suggestion-details-head h5 {
  font-weight: bold;
}

.suggestions-details {
  font-weight: 600;
}

.doc-viewer {
  width: 100%;
}

// .add-counter-sub {
//   margin-top: 20px;
//   margin-left: 100px;
// }
.two-fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-fields {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-fields input {
  width: 20px;
  margin-right: 50px;
}

.border-line {
  border: 1px solid gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  border-radius: 10px;
  height: 30px;
  padding: 0px 5px 2px 5px;
}

.terminal-modal-form {
  max-width: 700px;
  height: auto;
  size: lg;
}

// .rtk-form-details {
//   width: 100vw;
//   position: static;
//   z-index: 10000;
// }
.surveytask {
  width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.add-formHeader {
  margin-left: 37px;
  padding-top: 10px;
}

.main-class-single-row label {
  font-weight: 500;
}

.main-class-single-row input {
  height: 40px;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 0.2rem;
  outline: none;
  padding: 0 1rem;
  margin-top: 0.3rem;
  font-size: 1rem;
}

.hradofficecontats {
  background-color: #f2f2f2;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 10px;
}

.headofficeedit {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 780px) {
  #logo {
    height: 500px;
  }

  #card-list {
    width: 100%;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    padding: 0% !important;
  }

  .card-list {
    padding: 15px 5px 15px 5px !important;
  }

  .add-counter-sub {
    margin-bottom: 30px !important;
  }

  .keycontacts {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    border: 1px solid #2f3d53;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
    flex-direction: column;
    gap: 20px;
  }

  .validation-error-message {
    color: red;
  }

  .keycontacts .left {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
  }

  .keycontacts-fields :nth-child(1) {
    width: 35%;
  }
}

@media (max-width: 500px) {
  #logo {
    height: 250px;
  }

  #card-list {
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    padding: 0% !important;
    width: 100%;
  }

  .card-list {
    padding: 15px 5px 15px 5px !important;
  }

  .add-counter-sub {
    margin-bottom: 30px !important;
  }

  .add-counter-sub-client {
    margin-top: -20px;
  }
}

button.ml-auto.add.btn.btn-success {
  background-color: #425067;
}

button.ml-auto.btn.btn-success {
  background-color: #425067;
}

.status-green {
  text-transform: capitalize;
  align-items: center;

  &:before {
    content: "";
    background-color: greenyellow;
    padding: 7px;
    display: inline-block;
    border-radius: 50px;
    margin-right: 0.4em;
  }

  &.Active,
  &.paid {
    &:before {
      background-color: #6d767c;
    }
  }

  &.pending:before {
    background-color: #fa9917;
  }

  &.sent:before {
    background-color: #f3ca3e;
  }
}

button.resend {
  border: none;
  padding: 0;
  background: none;
}

button.resend-active {
  border: none;
  padding: 0;
  background: none;
  color: #144ec7;
}

.rdtOpen.drop-to-the-top .rdtPicker {
  margin-top: -300px;
}

.claim-modal {
  height: 600px;
  max-width: 70%;
}

span.mb-3.badge.bg-secondary.rounded-pill {
  border-radius: 50px !important;
  padding: 7px;
  font-size: 10px;
}

span.text-wrap.pointer {
  cursor: pointer;
}

.overlay {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.5;
  height: 100%;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 10;
}

.dialog {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  max-width: 100%;
  width: 30%;
}

.dialog__content {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dialog__title {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.dialog__description {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 1rem;
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.dialog__footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.dialog__cancel {
  border-radius: 0.75rem;
  font-weight: 500;
  margin-right: 1rem;
}

.dialog__cancel:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.dialog__cancel {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.dialog__cancel:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
}

.dialog__cancel {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dialog__cancel:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.dialog__confirm {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  font-weight: 500;
  margin-right: 1rem;
}

.dialog__confirm:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.dialog__confirm {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.dialog__confirm:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
}

.dialog__confirm {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dialog__confirm:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}

.forwards {
  flex-direction: column;

  .bold {
    font-size: 18px;
    font-weight: bold;
  }
}


.filter-reimbursement-model {
  height: 650px;
  width: 390px;
}


label.filter-date.form-label {
  padding-left: 1px !important;
}



.form-check-input:checked {
  background-color: #425067;
  border-color: #425067;
}

.input-wrapper{
  position: relative;


  .password-icon{
    position: absolute;
    top: 20%;
    right: 2%;
  }

  .password-icon-error{
    position: absolute;
    top: 10%;
    right: 2%;
  }

  .forget-password-icon{
    position: absolute;
    top: 55%;
    right: 2%;
  }

  .forget-password-icon-error{
    position: absolute;
    top: 35%;
    right: 2%;
  }

  .forget-password-icon-confirm-error{
    position: absolute;
    top: 40%;
    right: 2%;
  }

  .change-password-icon{
    position: absolute;
    top: 33%;
  }
}


@media only screen and (max-width:480px) { 
  .forward-button {
    width: 100%;
    margin-top: 50px;
position: absolute;

    button {
    width: 100%;
    }
  }

  .forward-btn-action{
    width: 100%;
    button {
      width: 100%;
  
      }

  }

  .cancel-btn{
    width: 100%;

    button {
      width: 100%;
  
      }
  }

  .forwardRef{
    margin:  0 !important;
  }

  .email-link-doc{
    height: 60% !important;
  }
  
}

@media only screen and (max-width:320px) {

  .btn-container{
    padding: 0 !important;
  }

  .forwardRef *{
    // padding-left:  2px !important;
    // padding-right: 2px !important;
  }

  .claim-header {
    padding: 10px 0px !important;
    justify-content: center;

    h5{
      display: flex;
    }
}

.email-link-doc{
  height: 40% !important;
}

}

@media only screen and (min-width:500px) {

  .email-link-doc{
    height: 70% !important;
  }

}

.claim-header.modal-header {
  padding: 20px 0px !important;
  justify-content: center;
}

.modal-dialog.mail-link-modal {

  top: 50%;
  transform: translate(-50%, -50%);
}

// .email-link-doc{
//   height: 50% !important;
// }

li.suMenu.nav-item {
  margin-left: 41px;
  font-size: 14px;
}

.btnLign{
  text-align: left;
}
.mrgn{
  margin-left: 20px !important;
}



li.suMenu.nav-item a.nav-link {
  margin: auto 0 auto 12px;
  font-size: 15px;
  line-height: 18px;
}

.app-layout > .app-content > .content .content-body .collapse-container .card-list .table thead tr td.notesRow{
  padding-left: 49px!important;
  padding-right: 60px !important;
}

.txtareFld{
  height: 150px;
}
.chkBoxDiv {
  margin: 35px 30px;
}

span.exportLatest {
  margin-left: 7px;
}

.app-layout > .app-content > .content .content-body .collapse-container .card-list .table thead  tr.text-nowrap.tableCss {
  font-size: 17px;
  line-height: 42px;
  color: white;
  background-color: #425067;
  text-align: center;
  vertical-align: top;
  white-space: normal !important;
}

table.table.table-borderless .minWdthx{
  width: 3%;
}
.minWdthxDescp{
  width: 22%;
}
.minWdthxAction{
  width: 3%!important;

}
td.chknorWrap {
  word-wrap: break-word !important;
  white-space: unset !important;
}