$separator-color: #d7d7d7;
$background-color: #f8f8f8;

$primary-text-color: #303030;
$secondary-text-color: #425067;

$border-radius: 5px;

$primary-color: #425067;
$secondary-color: #f0eff0;
$success-color: #425067;
$danger-color: #e2142d;

$primary-dark-color: darken(#344139, 10%);

$user-bar-height: 84px;
$footer-height: 61px;
$navbar-height: 100px;

$gray: #e2142d;

:export {
  primary_color: $primary-color;
  secondary_color: $secondary-color;
  success_color: $success-color;
  danger_color: $danger-color;
}
