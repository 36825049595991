@import "../bootstrap/mixins/gradients";

// Box Shadow
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -o-box-shadow: $shadow;
  box-shadow: $shadow;
}

// Single side border-radius
@mixin border-radius($radius...) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

// Single side box-sizing
@mixin box-sizing($box-sizing) {
  -webkit-box-sizing: $box-sizing;
  -moz-box-sizing: $box-sizing;
  box-sizing: $box-sizing;
}

// Clearfix

@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

// Transitions
@mixin transition($transition...) {
  -webkit-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -ms-transform: rotate($degrees); // IE9 only
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin translate($translate) {
  -webkit-transform: translate($translate);
  -ms-transform: translate($translate);
  transform: translate($translate);
}

@mixin translateX($translate) {
  -webkit-transform: translateX($translate);
  -ms-transform: translateX($translate);
  transform: translateX($translate);
}

@mixin translateY($translate) {
  -webkit-transform: translateY($translate);
  -ms-transform: translateY($translate);
  transform: translateY($translate);
}

//Background Image
@mixin background($imgpath,$position:0 0,$repeat: no-repeat,$size: cover) {
  background: {
    image: url($imgpath);
    position: $position;
    repeat: $repeat;
    size: $size;
  }
}

// Flex
@mixin display-flex($flex: flex, $flex-direction: row, $flex-wrap: wrap) {
  display: #{'-webkit'}-#{$flex};
  display: #{'-moz'}-#{$flex};
  display: #{'-ms'}-#{$flex};
  display: #{'-o'}-#{$flex};
  display: $flex;
  -webkit-flex-direction: $flex-direction;
  -ms-flex-direction: $flex-direction;
  flex-direction: $flex-direction;
  -webkit-flex-wrap: $flex-wrap;
  -ms-flex-wrap: $flex-wrap;
  flex-wrap: $flex-wrap;
}

@mixin flex($flex-grow: 1, $flex-shrink: 1, $flex-basis: auto) {
  -webkit-flex: $flex-grow $flex-shrink $flex-basis;
  -ms-flex: $flex-grow $flex-shrink $flex-basis;
  flex: $flex-grow $flex-shrink $flex-basis;
}

@mixin justify-content($justify-content) {
  -webkit-justify-content: $justify-content;
  justify-content: $justify-content;
}

@mixin align-items($align-items) {
  -webkit-align-items: $align-items;
  align-items: $align-items;
}

//Size
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

//Border Radius

// Single side border-radius

@mixin border-radius($radius: $border-radius) {
  @if $enable-rounded {
    border-radius: $radius;
  }
}

@mixin border-top-radius($radius) {
  @if $enable-rounded {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }
}

@mixin border-right-radius($radius) {
  @if $enable-rounded {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}

@mixin border-bottom-radius($radius) {
  @if $enable-rounded {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

@mixin border-left-radius($radius) {
  @if $enable-rounded {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

//Mixin Hover

// stylelint-disable indentation
@mixin hover {
  // TODO: re-enable along with mq4-hover-shim
  //  @if $enable-hover-media-query {
  //    // See Media Queries Level 4: https://drafts.csswg.org/mediaqueries/#hover
  //    // Currently shimmed by https://github.com/twbs/mq4-hover-shim
  //    @media (hover: hover) {
  //      &:hover { @content }
  //    }
  //  }
  //  @else {
  &:hover {
    @content;
  }
  //  }
}

@mixin hover-focus {
  @if $enable-hover-media-query {
    &:focus {
      @content;
    }
    @include hover {
      @content;
    }
  } @else {
    &:focus,
    &:hover {
      @content;
    }
  }
}

@mixin plain-hover-focus {
  @if $enable-hover-media-query {
    &,
    &:focus {
      @content;
    }
    @include hover {
      @content;
    }
  } @else {
    &,
    &:focus,
    &:hover {
      @content;
    }
  }
}

@mixin hover-focus-active {
  @if $enable-hover-media-query {
    &:focus,
    &:active {
      @content;
    }
    @include hover {
      @content;
    }
  } @else {
    &:focus,
    &:active,
    &:hover {
      @content;
    }
  }
}

// Gradient Mixin
@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
  background-image: linear-gradient($deg, $start-color, $end-color);
  background-repeat: repeat-x;
}

@mixin gradient-x($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;
}

@function top-shadow($depth) {
  $primary-offset: nth($shadowOffsetsTop, $depth) * 1px;
  $blur: nth($shadowBlursTop, $depth) * 4px;
  $color: rgba(black, nth($shadowOpacitiesTop, $depth));

  @return 0 $primary-offset $blur $color;
}

// Computes a bottom-shadow for a card effect.
// @param {Number} $depth - depth level
// @return {List}
@function bottom-shadow($depth) {
  $primary-offset: nth($shadowOffsetsBottom, $depth) * 1px;
  $blur: nth($shadowBlursBottom, $depth) * 5px;
  $color: rgba(black, nth($shadowOpacitiesBottom, $depth));
  @return 0 $primary-offset $blur $color;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

//Responsive Breakpoints

$breakpoints: (
  xxs: 420px,
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1440px,
  xxl: 2200px,
  xxxl: 2600px,
);

@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get(
      $breakpoints,
      $breakpoint
    ); // Write the media query.
    @media (min-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get(
      $breakpoints,
      $breakpoint
    ); // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

@function encodecolor($string) {
  @if type-of($string) == "color" {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote("#{$hex}");
  }
  $string: "%23" + $string;
  @return $string;
}
